
@import "../node_modules/@dankolz/ei-css-grid/grid.less";
.page-main-menu {
	.main-menu-block {
		.main-menu {
			list-style: none;
			
			a {
				text-decoration: none;
				color: inherit;
				
				h3 {
					margin-bottom: 0;
				}
				p {
					margin-top: 0;
				}

			}
		}
	}
	
}

.page-wrapper {
	header {
		position: relative;
	}
}


.grid {
	.ei-css-grid;
	& > .grid {
		grid-column: span 12;
	}
}